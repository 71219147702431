import React, { createContext, useContext, useState, useEffect } from "react";
import Api from "./baseApi";

export const Context = createContext(null);
export const useConfig = () => useContext(Context);

export default function ConfigProvider({ config, children }) {
	const [conf, confSet] = useState(false);

	useEffect( () => {
		if( !config.code ) {
			console.warn('No config.code');
			confSet(config);
			return;
		}
		const [query] = Api.GET({path:`/api/abo/vel/config?code=${config.code}`});
		query
		.then( rest => {
			rest.places.forEach( p => {
				if( p.notifyMail )
					p.notifyMail = [p.notifyMail];
			})
			config.places = {values: rest.places };

			config.steps.clubSelect.promo = () => {
				if( !rest.offer_url)
					return null;
				return <img src={rest.offer_url} alt='promo' />
			}
			confSet(config);
		})
		.catch( e => {
			console.error(e);
			confSet(config);
		})
	}, []);

	if( !conf )
		return null;

	return (
		<Context.Provider value={conf}>
			{children}
		</Context.Provider>
	);
};
