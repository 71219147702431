export default {
	code: 'oria-sport.com',
	appClassName: 'OriaSport',
	favicon: '/oriasport-favicon.png',
	title: "Je m'inscris | OriaSport",
	companyName: "Oria Sport",
	website: 'https://www.oria-sport.com/',
	notifyChannel: 'vel-oria-sport',
	header: {
		title: "Je m'abonne",
		logo: "/oriasport-logo.svg"
	},
	footer: {
		logo: "/oria-footer.svg",
		// TODO cgv: {
		// TODO 	link: 'https://www.genaeclub.com/bouc-bel-air/conditions-generales/',
		// TODO 	text: 'CGV'
		// TODO }
	},
	promoCode: false,
	payByCard: "PAYLINE",
	choice: {
		selectLabel: 'Sélectionner',
		selectedLabel: 'Sélectionné',
		mandatoryLabel: "Obligatoire",
		nameColor: '#EB5A4B',
		border: '#EFECEC',
		border_selected: 'lightgray',
		background: 'white',
		footerBg: '#EFECEC',
		footerBg_selected: '#f7f7f7',
		checkboxSelected: '#EB5A4B'
	},
	errorCode: {
		"api.error.sale.contact-invalid-data":"Veuillez essayer avec une autre adresse mail ou contacter le club directement.",
		default: "Une erreur est survenue avec Resamania2",
	},
	sign : {
		title: 'Signature du contrat',
		placeholder: "Code reçu par SMS",
		explain: "Entrer ici le code reçu par SMS pour signer le contrat",
		resendCodeLabel: "Renvoyer le code",
		badCodeLabel: "Code incorrect",
		signLabel: "Valider la signature",
	},
	payment: {
		loadingLabel: "Vérification en cours",
		informationLabel: "Informations de paiement",
		resumeLabel: "Total à payer par CB: ",
		payLabel: "Payer ",
		errorLabel: "Nous avons un soucis avec votre paiment.",
		errorTitle: "Une erreur est survenue",
	},
	loadingDefault: "Chargement en cours",
	loadingSmsCodeCheck: 'Vérification du code',
	loadingSmsCodeSend: 'Envoi du SMS',
	loadingTextUserCreation: "Création de votre compte",
	loadingTextCartCreation: "Création de votre panier",
	loadingTextAddressCheck: "Vérification de votre adresse",
	loadingTextOffersLoad: "Chargement des offres",
	plans: {
		noPlanFoundTitle: "Aucune formule",
		noPlanFoundTexts : [
			"Nous n'avons trouvé aucune formule pour ce club,",
			"veuillez réessayer plus tard ou changer de club via l'étape 1"
		],
		useImg : 'withText',
		beforeUser: false,
		imageBaseURL: 'https://api.resamania.com',
		title: "JE CHOISIS MA FORMULE",
		// splitCounterAbo: {
		// 	subsText : ["En illimité", "Dès 39€"],
		// 	passText: ['A la séance', "Dès 5€"]
		// },
		incitation: "Je choisis ma formule",
		feesLabel : "Licence",
		nofeesLabel: 'Aucune licence',
		autoSubmit: false,
		nextLabel: "Suivant",
	},
	productsLoader : {
		filterByClubIncluded: true,
		errorTitle: "Une erreur est survenue avec Resamania2",
		errorText: 'Veuillez réessayer plus tard ou avec un autre club',
	},
	options: false,
	regulars : false,
	recap: {
		title: "Ma sélection",
		myOffer: "Ma formule",
		myCounter: "Mes options et équipements",
		nextLabel: "Je confirme",
		payByCardLabel: "Total à payer par carte",
		payBySepaLabel : "Total prélevé directement",
	},
	sepa : {
		title: "RIB - Paiement Mensuel",
		validateLabel : "J’accepte par la présente les prélèvements automatiques SEPA",
		explain: "Veuillez mettre votre RIB ci-dessous pour valider votre abonnement et signer votre mandat de prélèvement SEPA",
		ownerLabel: "Titulaire du compte",
		ibanLabel: "IBAN",
		bicLabel: "BIC",
		bankLabel: "Nom de la banque",
	},
	contract: {
		title: "Votre contrat",
		signLabel: "Signer le contrat",
	},
	confirmation: {
		welcomeLabel: "Bienvenue chez",
		text: "Votre adhésion a bien été prise en compte, vous recevrez un e-mail de confirmation sous peu.",
		backLabel: "Retour au site."
	},
	steps: {
		display: {
			showShortNames: true,
		},
		clubSelect: {
			noAddress: true,
			showMap: "Masquer la carte",
			hideMap: "Voir la carte",
			title: 'JE CHOISIS MON CLUB',
			nextLabel: "Suivant",
			withMap: false,
			searchable:false,
			sameLine: true,
			autoSelect: true,
			//showOnly: 3,
			// confirm: {
			// 	txt: "En cochant cette case, je confirme souhaiter m’inscrire au club sélectionné, comme étant mon club d’entrainement principal."
			// }
		},
		userForm: {
			nextLabel: "Suivant",
			genderLabel: 'Civilité',
			gender_F: "Mme",
			gender_M: "M.",
			firstnameLabel: 'Prénom',
			lastnameLabel: "Nom",
			emailLabel: 'Adresse e-mail',
			phoneLabel: "Nº de téléphone portable",
			phoneExplain: "Utilisé pour la signature du contrat et du mandat SEPA",
			birthdateLabel: "Date de naissance",
			addressLabel: "Adresse",
			backLabel: "Retour",
			noClub: true,
			noGender: false,
			noAddress: false,
			noBirthdate: false,
			title: "MES COORDONNÉES",
			// optin: {
			// 	text: "J’ai lu et j'accepte les conditions générales de Genae Fitness Club",
			// 	//Href par club
			// 	moreLabel: "En savoir plus (Prévoir une pièce d'identité)",
			// 	step: 1,
			// }
		},
	},
	rootProperties: {
		'primary': '#AE1D22',
		'primary--dark': 'darken(#AE1D22, 15)',
		'placeholder': '#999999',
		'border': '#3d3d3d',
	},
	htmlStyle: `
		html {
			font-weight: 400;
			font-family: 'Roboto';
		}
	`,
	theme: {
		primary: '#EB5A4B',
		stepsDisplay: {
			color: {
				main: '#252525',
				bgFilled: '#252525',
				textFilled: 'white',

				subText: '#252525',
			}
		},
		loader : {
			border: 'black',
			border2: '#EB5A4B'
		},
		fontFamily: "Montserrat, sans-serif",
		buttonPrimary: {
			color: '#EB5A4B',
			text: '#fff',
		},
		buttonOther: {
			color: 'black',
			text: '#fff',
		},
		buttonSecondary: {
			color: "black",
			text: '#fff'
		},
		stepHeader: {
			fontWeight: 800,
			fontSize: "1.25rem",
			textTransform: 'uppercase',
			paddingTop:'1rem'
		}
	}
};
