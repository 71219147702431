import config_runupforme from './config-runupforme';
import config_club_planetefitness from './config-club-planetefitness';
import config_ofc from './config-ofc';
import config_genae from './config-genae';
import config_accrosport from './config-accrosport';
import config_atomfitness from './config-atomfitness';
import config_oriasport from './config-oriasport';

export default function getConfig() {
	const host = window.location.host;

	if( host.startsWith('pass.runupforme') ) {
		return config_runupforme;
	}

	if( host.startsWith('pass.club-planetefitness') ) {
		return config_club_planetefitness;
	}

	if( host.startsWith('pass.atom-fitnessbeziers') ) {
		return config_atomfitness;
	}

	if ( host.startsWith('pass.onefitnessclub.') ) {
		return config_ofc;
	}

	if ( host.startsWith('pass.genaeclub.') ) {
		return config_genae;
	}

	if ( host.startsWith('pass.accrosport.') ) {
		return config_accrosport;
	}

	if ( host.startsWith('pass.oria-sport.') ) {
		return config_oriasport;
	}

	throw new Error("No config for host " + host);
}
