import {useConfig} from './ConfigProvider';

const Footer = () => {
  const config = useConfig();

  const spanStyle = {
    marginTop: '0.2rem',
    marginBottom: '-.5rem',
    fontSize: '0.7rem',
    textAlign: 'center',
  };

  return (
    <footer style={{ backgroundColor: '#000', padding: '0rem 1rem 1rem 1rem', color: '#ffffff', textAlign:'center'}}>
      {config.footer.logo && <img src={config.footer.logo} alt={config.companyName} style={{width: '10rem', margin: '0.5rem auto'}}/> }
      
      <span style={spanStyle}>{new Date().getFullYear()} &copy; {config.companyName}</span>
      {config.footer.cgv && (
        <span style={{marginTop:'1rem', textDecoration:'underline'}}>
          <a target='_blank' href={config.footer.cgv.link} rel='noreferrer'>{config.footer.cgv.text}</a>
        </span>
      )}
    </footer>
  );
}

export default Footer;